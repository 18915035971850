<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <notification-types />
        </b-col>
        <b-col
          cols="12"
        >
          <title-text />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <start-date />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <end-date />
        </b-col>
        <b-col
          cols="12"
        >
          <content-text />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <button-label />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <button-url />
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col
        cols="12"
      >
        <image-card />
      </b-col>
    </b-row>
    <b-button
      variant="primary"
      :disabled="itemData.submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BCard, BCol, BRow, BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import NotificationTypes from '@/views/Admin/Notifications/elements/NotificationTypes.vue'
import TitleText from '@/views/Admin/Notifications/elements/TitleText.vue'
import ContentText from '@/views/Admin/Notifications/elements/ContentText.vue'
import ImageCard from '@/views/Admin/Notifications/elements/ImageCard.vue'
import StartDate from '@/views/Admin/Notifications/elements/sDate.vue'
import EndDate from '@/views/Admin/Notifications/elements/eDate.vue'
import ButtonLabel from '@/views/Admin/Notifications/elements/ButtonLabel.vue'
import ButtonUrl from '@/views/Admin/Notifications/elements/ButtonUrl.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    NotificationTypes,
    TitleText,
    ContentText,
    ImageCard,
    StartDate,
    EndDate,
    ButtonLabel,
    ButtonUrl,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      imageUrl: this.$store.state.app.baseURL,
      snowOption: {
        theme: 'snow',
      },
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['notifications/dataItem']
    },
  },
}
</script>
