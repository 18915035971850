<template>
  <b-form-group
    label="Yayın Başlangıç"
    label-for="sdate"
  >
    <validation-provider
      #default="{ errors }"
      name="Yayın Başlangıç"
      rules="required"
    >
      <b-form-datepicker
        id="sdate"
        v-model="itemData.sdate"
        placeholder="Yayın Başlangıç"
        v-bind="{labelNoDateSelected: 'Yayın Başlangıç',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
        locale="tr"
        start-weekday="1"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { BFormGroup, BFormDatepicker } from 'bootstrap-vue'

export default {
  name: 'Sdate',
  components: {
    BFormGroup,
    BFormDatepicker,
    ValidationProvider,
  },
  computed: {
    itemData() {
      return this.$store.getters['notifications/dataItem']
    },
  },
}
</script>
