<template>
  <b-form-group
    label="Yayın Bitiş"
    label-for="ndate"
  >
    <validation-provider
      #default="{ errors }"
      name="Yayın Bitiş"
      rules="required"
    >
      <b-form-datepicker
        id="edate"
        v-model="itemData.edate"
        placeholder="Yayın Bitiş"
        v-bind="{labelNoDateSelected: 'Yayın Bitiş',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
        locale="tr"
        start-weekday="1"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { BFormGroup, BFormDatepicker } from 'bootstrap-vue'

export default {
  name: 'Edate',
  components: {
    BFormGroup,
    BFormDatepicker,
    ValidationProvider,
  },
  computed: {
    itemData() {
      return this.$store.getters['notifications/dataItem']
    },
  },
}
</script>
